@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Playwrite+FR+Moderne:wght@100..400&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-text{
  font-family: "Playwrite FR Moderne", cursive;
}

.hero-text{
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
  text-decoration: none solid rgb(29, 29, 29);
  font-style: normal;
}

h1 span{
  display: inline-block;
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
  text-decoration: none solid rgb(29, 29, 29);
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.vdo{
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: -100;
  background-position: 50%;
  background-size: cover;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  background-clip: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;