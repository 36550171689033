.remaining-rows {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    grid-gap: 30px; 
    max-width: 1400px; 
    margin: 0 auto; 
    padding: 0 20px;
    width: 100%; 
}

@media (max-width: 1400px) {
    .remaining-rows {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media (max-width: 786px) {
    .remaining-rows {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 400px) {
    .remaining-rows {
        grid-template-columns: repeat(1, 1fr); 
    }
    
}

.team-member {
    background-color: #EAF8D9;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    height: 400px;
    position: relative;
    padding: 0;
    overflow: hidden;
    transition: height 0.3s ease, filter 0.3s ease, transform 0.3s ease;
}

.member-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 20px; 
    margin-top: 60px;
    
}

.member-info {
    position: absolute;
    top: 1vh;
    left: 1vw;
    width: calc(100% - 20px); 
    z-index: 2;
}

.member-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #333;
}

.member-info p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

@media (max-width: 786px) {
    .member-info h3 {
        font-size: 28px; 
    }

    .member-info p {
        font-size: 14px; 
    }
}

.social-links {
    position: absolute;
    top: 0.5vw;
    right: 0.5vw;
    z-index: 3; 
    display: flex;
    gap: 10px; 
}

.social-links a {
    color: #333;
    text-decoration: none;
}

.social-icon {
    font-size: 25px;
}

@media (max-width: 786px) {
    .social-icon {
        font-size: 28px; 
    }
}

@media (max-width: 650px) {
    .member-info h3 {
        font-size: 25px;
    }
}



@media (max-width: 520px) {
    .team-member {
        height: auto; 
    }
    .member-info h3{
        font-size: 15px;
    }
    .member-info p{
        font-size: 10px;
    }
    .social-icon{
        font-size: 20px;
    }
}
@media (max-width: 400px) {
    .social-icon {
        font-size: 32px; 
    }
    .member-info h3{
        font-size: 20px;
        
    }
    .member-info p{
        font-size: 14px;
        font-weight: 600;
    }
}
.team-member {
    /* Existing styles */
    transition: all 0.3s ease;
  }
  
  .team-member.grayscale {
    filter: grayscale(80%);
    transform: scale(0.98);
  }
  
  /* Rest of your CSS remains the same */
  