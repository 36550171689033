.animated-line {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.7s ease-in-out forwards;
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
      filter: blur(1px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
      filter: blur(0);
    }
  }
  
  
  
  