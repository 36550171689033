.card:hover{
    animation: expand 170ms ease-in-out;
    animation-fill-mode: forwards;
}
.zoom:hover{
    animation: expand 170ms ease-in-out;
    animation-fill-mode: forwards;
}
@keyframes expand {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.02);
        
    }
}
